import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Footer from "../components/Footer"

const SecondPage = () => (
  <Layout>
    <Seo title="Page two" />
    <div>
  <section id="about">
          <div className="container">
              <div className="row row-cols-1  py-4 py-lg-5">
                  <div className="col-lg-6 f-18  mx-auto text-left">
                      <p>Revive tekkis hobist klassikaliste ja huvitavate autode vastu, millest kujunes edasi soov luua minimalistlik platvorm, mis nad ühte kohta koondaks. </p>
                      <p>Soovid enda autot Revive platvormi kaudu müüa siis selleks on mitu võimalust, alustuseks kirjuta meile ja saada mõni pilt. Kui auto tundub piisavalt aus ja põnev siis anname sulle teada.
                          Edasi on sul valida kolme variandi vahel:  </p>
                          <ul className="text-left">
                              <li>Auto on sinu käes, teed autost põhjalikud pildid ja koos sinuga paneme kuulutuse teksti kokku. Kuni 2021 aasta lõpuni me selle eest raha ei küsi.</li>
                              <li>Sa tood auto meie garaazi ja me teeme ise pildid ja tegeleme müügiga. Hind minimaalselt 300€ või 5% müügihinnast</li>
                              <li>Kui sul väga kiire ja tahad kohe raha siis pakume võimalust sult auto kohe välja ja tegeleme ise edasi.
                              </li>
                          </ul>
                          Kõige tähtsam on, et auto peab olema huvitav ning kõik head omadused ja ka vead paneme ilusti kirja
                  </div>
              </div>
          </div>
    </section>
    <Footer/>
    </div>
  </Layout>
)

export default SecondPage
